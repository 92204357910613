

























import { Component, Vue } from 'vue-property-decorator'
import notificationService, { NotificationItem } from '@/services/notificationService'
import utility from '@/common/utility'

@Component
export default class NotificationIndex extends Vue {
  isLoading = false
  notifications: NotificationItem[] = []

  async mounted () {
    await this.loadUsersAsync()
  }

  async loadUsersAsync () {
    this.isLoading = true
    const res = await notificationService.getNotificationsAsync()
    this.isLoading = false
    if (res.Result) {
      this.notifications = utility.freezeArrayAndItems(res.Result)
    } else {
      await this.$alert(res.Error)
    }
  }
}
